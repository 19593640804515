import './Footer.css';
const Footer = () => <footer style={{
    background: 'linear-gradient(to right, #E316FE, #877BFE, #5CF3FE)',
    padding: '20px',
    marginTop: '20px'
}}>
    <p className="address">© 2024 MagicNailz. Alle Rechte vorbehalten.</p>
    <p className="address">Schupfarterstrasse 1, Münchwilen Aargau, Schweiz</p>
    <p className="address">Email: magic-nailz@hotmail.com | Telefon: +41 79 959 54 95</p>
</footer>;
export default Footer