import React, {useState} from 'react';
import {Navbar, NavbarBrand, Nav, NavItem, NavLink} from 'reactstrap';
import './header.css';
import {GiHamburgerMenu} from "react-icons/gi";
import Sidebar from './Sidebar'; // Importieren Sie die Sidebar-Komponente

function Header({currentPage, setCurrentPage}) {
    const handleClick = page => setCurrentPage(page);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

    return <div>
        <Navbar style={{background: 'linear-gradient(to right, #E316FE, #877BFE, #5CF3FE)'}} light expand="md">
            <NavbarBrand className="NavbarBrand" href="/"><img
                alt="logo"
                src="/favicon.ico"
                style={{
                    height: 50,
                    width: 50
                }}
            />
                MagicNailz
            </NavbarBrand>
            <Nav navbar>
                <NavItem>
                    <NavLink className="nav-link" onClick={() => handleClick('home')}>Home</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className="nav-link" onClick={() => handleClick('appointments')}
                    >Termine</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className="nav-link" onClick={() => handleClick('offers')}
                    >Angebot</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className="nav-link" onClick={() => window.open('https://www.instagram.com/_Magic_Nailz/', '_blank')}
                             >Galerie</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className="nav-link" onClick={() => handleClick('me')}>Über mich</NavLink>
                </NavItem>
                <NavItem>
                    <button onClick={toggleSidebar} className="hamburger"><GiHamburgerMenu size={28}/></button>
                </NavItem>
            </Nav>
        </Navbar>
        <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
    </div>;
}

export default Header;