import React from "react";
import {Container} from "reactstrap";

const GalleryContainer = () => {
    return <div>
        <Container>
            <div className="center">
                <h1>Galerie</h1>

                Nice Gallery here ;-)
            </div>
        </Container>
    </div>;
};
export default GalleryContainer;