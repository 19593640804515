import React from "react";
import {Container} from "reactstrap";

const OffersContainer = () => {
    return <div>
        <Container>
            <div className="center">
                <h1>Meine Angebote</h1>
                <div>
                    <h2>Neumodelage</h2>
                    <div>mit Tips</div>
                    <div>Naturnagelverstärkung</div>

                    <h2>Auffüllen</h2>
                    <div>nach 3 Wochen</div>
                    <div>nach 4 Wochen</div>
                    <div>ab 6 Wochen wird eine Neumodellage verrechnet.</div>

                    <h2>French</h2>
                    <div>French Weiss/Farbig</div>
                    <div>French Glitter</div>

                    <h2>Nailart / Design</h2>
                    <div>1 Nagel</div>
                    <div>2 Nägel</div>
                    <div>4 Nägel</div>
                    <div>Alle Nägel</div>

                    <h2>Reparaturen</h2>
                    <div>1 Reparatur beim Auffüllen gratis</div>
                    <div>ab 2. Nagel beim Auffüllen</div>
                    <div>Reparaturen ausserterminlich pro Nagel</div>
                    <div>Entfernung der Modellage</div>
                </div>
            </div>
        </Container>
    </div>;
};
export default OffersContainer;