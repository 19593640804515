import './Diashow.css';
import {Card, CardBody, CardGroup, CardImg, CardSubtitle, CardText, CardTitle} from "reactstrap";
import React from "react";

const Diashow = () => {
    return <div className="center">
        <h1>Begeisterte Kunden und Kundinnen</h1>
        <div className="cardgroup">
            <CardGroup>
                <Card>
                    <CardImg
                        alt="Card image cap"
                        src="../img/diashow/dia1.jpg"
                        top
                        width="100%"
                    />
                    <CardBody>
                        <CardTitle tag="h5">
                            Yvonne
                        </CardTitle>
                        <CardSubtitle
                            className="mb-2 text-muted"
                            tag="h6"
                        >
                            Blue Heart Nailart
                        </CardSubtitle>
                        <CardText>
                            Melissa macht super coole Nägel! Sie geht total auf meine Wünsche ein und ich bin immer
                            richtig happy!
                        </CardText>
                    </CardBody>
                </Card>
                <Card>
                    <CardImg
                        alt="Card image cap"
                        src="../img/diashow/dia2.jpg"
                        top
                        width="100%"
                    />
                    <CardBody>
                        <CardTitle tag="h5">
                            Patrick
                        </CardTitle>
                        <CardSubtitle
                            className="mb-2 text-muted"
                            tag="h6"
                        >
                            Maniküre
                        </CardSubtitle>
                        <CardText>
                            Ich bin froh, dass ich auch als Mann herzlich willkommen bin. Melissa macht das super und ich
                            fühle mich immer wohl!
                        </CardText>
                    </CardBody>
                </Card>
                <Card>
                    <CardImg
                        alt="Card image cap"
                        src="../img/diashow/dia3.jpg"
                        top
                        width="100%"
                    />
                    <CardBody>
                        <CardTitle tag="h5">
                            Nadja
                        </CardTitle>
                        <CardSubtitle
                            className="mb-2 text-muted"
                            tag="h6"
                        >
                            Classic Nude
                        </CardSubtitle>
                        <CardText>
                            Melissa setzt alles genau so um, wie ich es haben möchte. Bin begeistert und würde nie mehr
                            woanders hin!
                        </CardText>
                    </CardBody>
                </Card>
            </CardGroup>
        </div>
    </div>
};

export default Diashow;