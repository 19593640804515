import React from "react";
import {Container} from "reactstrap";
import "./MeContainer.css";

const Me = () => {
    return <div>
        <Container>
            <div className="slogan">
                Termin gepasst, Nägel gemacht, Selbstbewusstsein entfacht – denn Style beginnt an den Fingerspitzen und
                deine innere Schönheit lacht.
            </div>
            <img src="../img/separator/Separator-Muster.png" alt="separator" className="separator"/>
            <div className="center-me">
                <h1>Über mich</h1>
                <div className="row">
                    <div className="element1">Hallo, ich bin Melissa Rosa, deine Nagelkünstlerin in Münchwilen. Mit bald 30 Jahren und einer
                        Leidenschaft für Schönheit und Kreativität habe ich meine Liebe zum Nageldesign entdeckt.
                        Ursprünglich
                        als Primarlehrerin tätig, fand ich in meiner Freizeit Erfüllung in meinen Hobbys: Reiten, Singen
                        und
                        Beauty. In meinem gemütlichen Zuhause teile ich meine Welt mit zwei Katzen und meinem treuen
                        Hund.
                        <br/><br/>
                        Mit einem Herz, das für meine Schüler genauso schlägt wie für meine tierischen Begleiter, habe
                        ich
                        ein
                        zweites Standbein aufgebaut, das meine kreative Ader in den Fokus rückt. Das Gestalten
                        einzigartiger
                        Nägel ist für mich nicht nur ein Beruf, sondern eine Leidenschaft, die ich mit Hingabe und Seele
                        verfolge. Jeder Pinselstrich erzählt eine Geschichte, und ich freue mich darauf, deine
                        Persönlichkeit durch meine Kunst zum Ausdruck zu bringen.
                        <br/><br/>
                        Lass uns gemeinsam eine Reise in die Welt der Schönheit
                        antreten und deine Nägel in wahre Kunstwerke verwandeln. Ich freue mich darauf, dich
                        kennenzulernen
                        und
                        gemeinsam mit dir die Schönheit in jedem Detail zu entdecken!
                        <div>Deine Melissa</div>
                    </div>
                    <img src="../img/me/MelissaRosa.jpg" alt="MelissaRosa" className="element2 melissaRosa"/>
                </div>
            </div>

        </Container>
    </div>;
};
export default Me;

