import React from 'react';
import {FaInstagram, FaEnvelope, FaCalendarAlt} from 'react-icons/fa';
import './SocialMediaLinks.css';

const SocialMediaLinks = () => {
    const handleEmail = () => {
        const subject = encodeURIComponent("Buchungsanfrage Magic Nailz");
        const body = encodeURIComponent("Hallo Melissa\n\nGerne würde ich bei dir einen Termin buchen für eine:\nNeumodelage [  ]\nAuffüllen [  ]\n\nTerminvorschlag gem. Öffnungszeiten: __.__.2024     __:__Uhr\n\nLiebe Grüsse");
        window.location.href = `mailto:magic-nailz@hotmail.com?subject=${subject}&body=${body}`;
    };

    return <div className="social-media-links">
        <a href="/booking">
            <FaCalendarAlt size={20}/>
        </a>
        <a href="https://www.instagram.com/_Magic_Nailz/" target="_blank" rel="noopener noreferrer">
            <FaInstagram size={20}/>
        </a>
        <div onClick={handleEmail} className="email-link">
            <FaEnvelope size={20}/>
        </div>
    </div>;
};

export default SocialMediaLinks;