
import React from "react";
import Carousel from "./Carousel";
import NailExpert from "./NailExpert";
import Booking from "./Booking";
import {Container} from "reactstrap";
import Diashow from "./Diashow";
import AdditionalInformations from "./AdditionalInformations";

const ContentContainer = ({currentPage, setCurrentPage}) => {
    return <div>
        <Carousel />
        <Container>
            <NailExpert />
            <Booking />
            <Diashow />
            <AdditionalInformations currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </Container>
    </div>;
};
export default ContentContainer;

