
import React from "react";
import Booking from "../content/Booking";
import {Container} from "reactstrap";

const AppointmentsContainer = () => {
    return <div>
        <Container>
            <Booking />
        </Container>
    </div>;
};
export default AppointmentsContainer;