import React, {useState} from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
} from 'reactstrap';
import './Carousel.css';

const items = [
    {
        src: '../img/slider/slider1.jpg',
        // altText: 'Slide 1',
        caption: 'Slide 1',
        key: 1,
    },
    {
        src: '../img/slider/slider2.jpg',
        // altText: 'Slide 2',
        caption: 'Slide 2',
        key: 2,
    },
    {
        src: '../img/slider/slider3.jpg',
        // altText: 'Slide 3',
        caption: 'Slide 3',
        key: 3,
    },
    {
        src: '../img/slider/slider4.jpg',
        // altText: 'Slide 4',
        caption: 'Slide 4',
        key: 4,
    },
];

function CarouselContainer(args) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = newIndex => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map(item =>
        <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.src}
        >
            <img src={item.src} alt={item.altText} className="full-width-carousel-images"/>
        </CarouselItem>);

    return <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        className="hide-caption"
        {...args}
    >
        <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
        />
        <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
        />
    </Carousel>;
}

export default CarouselContainer;