import React, {useState, useEffect} from 'react';
import './app.css';
import ContentContainer from "./content/ContentContainer";
import Header from "./Header";
import Footer from "./Footer";
import Me from "./me/Me";
import AppointmentsContainer from "./Appointments/appointmentsContainer";
import GalleryContainer from "./Gallery/GalleryContainer";
import OffersContainer from "./Offers/OffersContainer";
import SocialMediaLinks from "./content/SocialMediaLinks";

function App() {
    const [currentPage, setCurrentPage] = useState('home');

    useEffect(() => {
        const pageElement = document.getElementById('page');
        pageElement.classList.add('page-transition');
        const transitionDuration = 500;
        setTimeout(() => pageElement.classList.remove('page-transition'), transitionDuration);
    }, [currentPage]);

    return <div id="page">
        <Header currentPage={currentPage} setCurrentPage={setCurrentPage} />
        {currentPage === 'home' && <ContentContainer currentPage={currentPage} setCurrentPage={setCurrentPage}/>}
        {currentPage === 'me' && <Me/>}
        {currentPage === 'appointments' && <AppointmentsContainer/>}
        {currentPage === 'gallery' && <GalleryContainer/>}
        {currentPage === 'offers' && <OffersContainer/>}
        <Footer/>
        <SocialMediaLinks />
    </div>;
}


export default App;