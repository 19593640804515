import React from "react";
import './NailExpert.css';

const Booking = () => {
    const handleEmail = () => {
        const subject = encodeURIComponent("Buchungsanfrage Magic Nailz");
        const body = encodeURIComponent("Hallo Melissa\n\nGerne würde ich bei dir einen Termin buchen für eine:\nNeumodelage [  ]\nAuffüllen [  ]\n\nTerminvorschlag gem. Öffnungszeiten: __.__.2024     __:__Uhr\n\nLiebe Grüsse");
        window.location.href = `mailto:magic-nailz@hotmail.com?subject=${subject}&body=${body}`;
    };

    return (
        <div className="center">
            <h1>Buchen Sie einen Termin</h1>
            <button onClick={handleEmail} className="booking-button">
                Termin buchen
            </button>
            <div>Termine buchbar ab: 19.02.2024</div>
        </div>
    );
};

export default Booking;