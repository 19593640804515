import './AdditionalInformations.css';
import React from "react";

function AdditionalInformations({currentPage, setCurrentPage}) {
    const handleClick = page => setCurrentPage(page);

    return <div className="threeSections">
        <div className="center">
            <div className="column">
                <h1>Angebot</h1>
                <div>Gerne zeige ich dir mehr über meine Angebote</div>
                <button onClick={() => handleClick('offers')} className="more-button">Mehr erfahren</button>
            </div>
        </div>
        <div className="center">
            <div className="column">
                <h1>Galerie</h1>
                <div>In meiner Galerie findest du einige meiner Arbeiten</div>
                <button onClick={() => window.open('https://www.instagram.com/_Magic_Nailz/', '_blank')}
                        className="gallery-button">Zur Galerie
                </button>
            </div>
        </div>

        <div className="column">
            <div className="column">
                <h1>Öffnungszeiten</h1>
                <div className="opening-hours">
                    <h5>Montag</h5>
                    <div>14:00 - 19:00</div>
                    <h5>Dienstag</h5>
                    <div>17:00 - 19:00</div>
                    <h5>Mittwoch</h5>
                    <div>10:30 - 19:00</div>
                    <h5>Samstag</h5>
                    <div>09:00 - 13:00</div>
                    <h5>Donnerstag, Freitag, Sonntag</h5>
                    <div>Geschlossen</div>
                    <h6>Induviduelle Termine auf Anfrage</h6>
                </div>
            </div>
        </div>
    </div>;
}

export default AdditionalInformations;