
import './Sidebar.css';

function Sidebar({isOpen, toggleSidebar, setCurrentPage, currentPage}) {
    const handleClick = page => {
        setCurrentPage(page);
        toggleSidebar();
    };


    return <div>
        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
            <div className="sidebar-link" onClick={() => handleClick('home')}>Home</div>
            <div className="sidebar-link" onClick={() => handleClick('appointments')}>Termine</div>
            <div className="sidebar-link" onClick={() => handleClick('offers')}>Angebot</div>
            <div className="sidebar-link" onClick={() => window.open('https://www.instagram.com/_Magic_Nailz/', '_blank')}>Galerie</div>
            <div className="sidebar-link" onClick={() => handleClick('me')}>Über mich</div>
        </div>
    </div>;
}

export default Sidebar;